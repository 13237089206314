import { Model } from '@vuex-orm/core'
import api from '@/core/services/openapi.service'

export class CloudServer extends Model {
    static entity = 'cloud_servers';

    static route = 'servers-single'

    static primaryKey = 'id'

    static fields () {
        return {
            id: this.number(0),
            title: this.string(''),
            hostname: this.string(''),
            cores: this.number(1),
            memory: this.number(1024),
            project_uuid: this.string(''),
            user: this.string('')
        }
    }

    static async fetchAll () {
        const response = await api.getCloudServers()
        await CloudServer.insertOrUpdate(response)
        return CloudServer.all()
    }

    static async fetchSingle (id) {
        const response = await api.getCloudServer(id)
        await CloudServer.insertOrUpdate(response)
        return CloudServer.find(id)
    }

    static filter(filter, project_uuid = null) {
        let query = this.query().where(p =>
            ((p.hostname || '').includes(filter)) ||
            ((p.title || '').includes(filter)) ||
            (p.id.toString().includes(filter))
        )
        if (project_uuid) { query = query.where('project_uuid', project_uuid) }
        return query
    }
}
