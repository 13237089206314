import { Model } from '@vuex-orm/core'
import api from '@/core/services/openapi.service'

export class IpAddress extends Model {
    static entity = 'ip_addresses';
    static primaryKey = 'address'

    static route = 'addresses-single'

    static fields () {
        return {
            id: this.number(0),
            address: this.string(''),
            rdns: this.attr(null),
            range: this.attr(null),
            reserved: this.attr(null),
            type: this.attr(null),
            user: this.attr(null),
            address_gateway: this.string(''),
            vlan_id: this.attr(null),
            network_user: this.attr(null),
            address_broadcast: this.string(''),
            address_network: this.string(''),
            address_type: this.attr(null),
            network_netmask: this.attr(null),
            project_uuid: this.string('')
        }
    }

    static async fetchAll (project_uuid = null) {
        const response = await api.getAddresses(project_uuid ? {
            easyFilter: {
                project_uuid
            }
        } : null)

        await this.insertOrUpdate({
            data: response.data
        })
    }

    static async fetchFree () {
        const response = await api.getAddresses({
            params: {
                filter: {
                    fields: [
                        {
                            key: 'reserved',
                            operator: 'IS NULL'
                        },
                        {
                            key: 'type',
                            operator: 'IS NULL'
                        }
                    ]
                }
            }
        })
        await this.insertOrUpdate(response)

        return response.data
    }

    static filter(filter, project_uuid = null) {
        let query = this.query().where(p => p.address.includes(filter))
        if (project_uuid) { query = query.where('project_uuid', project_uuid) }
        return query
    }

    static async fetchSingle (address) {
        const response = await api.getAddress(address)
        await this.insertOrUpdate({
            data: response.data
        })
        return this.find(address)
    }
}
