import { Model } from '@vuex-orm/core'
import api from '@/core/services/openapi.service'

export class Backup extends Model {
    static entity = 'backups'
    static primaryKey = 'id'

    static fields () {
        return {
            id: this.number(0),
            cloud_server_id: this.number(0),
            title: this.attr(null),
            path: this.attr(null),
            size: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null)
        }
    }

    static createById = async id => {
        const res = await api.createCloudServerBackup(id)
        await Backup.insertOrUpdate({ data: res.data })
        return res.data
    }

    static fetchById = async id => {
        const res = await api.getCloudServerBackups(id)
        await Backup.deleteAll()
        await Backup.insertOrUpdate({ data: res.data })
        return res.data
    }

    static deleteById = async (id, backup_id) => {
        const res = await api.deleteCloudServerBackup(id, backup_id)
        await Backup.delete(backup_id)
        return res.data
    }

    static restoreById = async (id, backup_id) => {
        const res = await api.restoreCloudServer(id, { backup_id })
        return res.data
    }
}
