import { Model } from '@vuex-orm/core'
import api from '@/core/services/openapi.service'

export class CloudServerScheduledTask extends Model {
    static entity = 'tasks';
    static primaryKey = 'id'

    static url = (id, task_id) => `/cloud/servers/${id}/scheduled-tasks${task_id ? `/${task_id}` : ''}`

    static fields () {
        return {
            id: this.number(0),
            cloud_server_id: this.number(0),
            type: this.attr(null),
            last_execution: this.attr(null),
            next_execution: this.attr(null),
            interval: this.attr(null),
            action: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            deleted_at: this.attr(null)
        }
    }

    static fetchById = async id => {
        const res = await api.getCloudServerScheduledTasks(id)
        await CloudServerScheduledTask.deleteAll()
        await CloudServerScheduledTask.insertOrUpdate({ data: res.data })
        return res.data
    }

    static createById = async (id, { action, next_execution, interval }) => {
        const res = await api.createCloudServerScheduledTask(id, { action, next_execution, interval })
        await CloudServerScheduledTask.insertOrUpdate({ data: res.data })
        return res.data
    }

    static deleteById = async (id, task_id) => {
        const res = await api.deleteCloudServerScheduledTask(id, task_id)
        await CloudServerScheduledTask.delete(task_id)
        return res.data
    }
}
