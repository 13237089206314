import { Model } from '@vuex-orm/core'
import api from '@/core/services/openapi.service'

export class Project extends Model {
    static entity = 'projects';

    static route = 'projects-single'

    static primaryKey = 'uuid'

    static fields () {
        return {
            uuid: this.string(''),
            title: this.string(''),
            customer_id: this.number(null),
            description: this.attr(null)
        }
    }

    static async fetchAll () {
        const response = await api.getProjects()

        await this.insert({
            data: response.data
        })
    }

    static async fetchSingle (uuid) {
        const response = await api.getProject(uuid)
        await Project.insertOrUpdate(response)
        return Project.find(uuid)
    }
}
