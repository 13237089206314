import { Model } from '@vuex-orm/core'
import api from '@/core/services/openapi.service'

export class Network extends Model {
    static entity = 'networks';

    static route = 'networks-single'

    static primaryKey = 'id'

    static fields () {
        return {
            id: this.number(0),
            tag: this.number(0),
            is_public: this.boolean(false),
            title: this.attr(''),
            descr: this.attr('')
        }
    }

    static async fetchAll () {
        const response = await api.getNetworks()

        await this.insert({
            data: response.data
        })
    }

    static async fetchSingle (id) {
        const response = await api.getNetworks()
        await Network.insertOrUpdate(response)
        return Network.find(id)
    }

    static filter(filter, project_uuid = null) {
        let query = this.query().where(p =>
            ((p.title || '').includes(filter)) ||
            (p.id.toString().includes(filter))
        )
        if (project_uuid) { query = query.where('project_uuid', project_uuid) }
        return query
    }
}
