export const state = {
    classes: {}
}

export const getters = {
    getClasses: state => position => {
        if (typeof position !== 'undefined') {
            return state.classes[position]
        }
        return state.classes
    }
}

export const actions = {
    addBodyClassName (context, className) {
        document.body.classList.add(className)
    },

    removeBodyClassName (context, className) {
        document.body.classList.remove(className)
    },

    addClassName ({ commit }, payload) {
        commit('SET_CLASSNAME_BY_POSITION', payload)
    }
}

export const mutations = {
    SET_CLASSNAME_BY_POSITION (state, payload) {
        const { position, className } = payload
        if (!state.classes[position]) {
            state.classes[position] = []
        }
        state.classes[position].push(className)
    }
}
