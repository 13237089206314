import objectPath from 'object-path'
import merge from 'deepmerge'
import config from '@/core/config/layout.config.json'

export const state = {
    config: config,
    initial: config
}

export const getters = {
    /**
     * Get config from layout config
     * @param state
     * @returns {function(path, defaultValue): *}
     */
    layoutConfig: state => (path, defaultValue) => {
        return objectPath.get(state.config, path, defaultValue)
    }
}

export const actions = {
    /**
     * Set and replace the whole config
     * @param state
     * @param payload
     */
    setLayout (state, payload) {
        state.commit('SET_LAYOUT', payload)
    },

    /**
     * Reset the config at the initial state
     * @param state
     */
    resetLayout (state) {
        state.commit('RESET_LAYOUT')
    },

    /**
     * Reset the config using saved config in the cache by the layout builder
     * @param state
     */
    overrideLayout (state) {
        state.commit('OVERRIDE_LAYOUT')
    },

    /**
     * Override config by page level
     * @param state
     * @param payload
     */
    overridePageLayout (state, payload) {
        state.commit('OVERRIDE_PAGE_LAYOUT', payload)
    }
}

export const mutations = {
    SET_LAYOUT (state, payload) {
        state.config = payload
    },

    RESET_LAYOUT (state) {
        state.config = Object.assign({}, state.initial)
    },

    OVERRIDE_LAYOUT (state) {
        state.config = state.initial = Object.assign(
            {},
            state.initial,
            JSON.parse(localStorage.getItem('config'))
        )
    },

    OVERRIDE_PAGE_LAYOUT (state, payload) {
        state.config = merge(state.config, payload)
    }
}
