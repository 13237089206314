import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'

import modules from './modules'

import { Project } from '@/core/services/store/models/project'
import { Network } from '@/core/services/store/models/network'
import { IpAddress } from '@/core/services/store/models/ip_address'
import { FirewallRuleSet } from '@/core/services/store/models/firewall_rule_set'
import { FirewallAddressSet } from '@/core/services/store/models/firewall_address_set'
import { Backup } from '@/core/services/store/models/backup'
import { CloudServerScheduledTask } from '@/core/services/store/models/cloudServerScheduledTask'
import { CloudServer } from '@/core/services/store/models/cloud_server'
import { Customer } from '@/core/services/store/models/customer'
import { CloudServerStatus } from '@/core/services/store/models/cloud_server_status'
import { Pricing } from '@/core/services/store/models/pricing'

Vue.use(Vuex)

const database = new VuexORM.Database()
database.register(Project)
database.register(Network)
database.register(IpAddress)
database.register(FirewallRuleSet)
database.register(FirewallAddressSet)
database.register(Backup)
database.register(CloudServerScheduledTask)
database.register(CloudServer)
database.register(CloudServerStatus)
database.register(Customer)
database.register(Pricing)

const store = new Vuex.Store({
    modules: modules,
    namespaced: true,
    plugins: [VuexORM.install(database)],
    strict: true
})

// auto init dispatcher
for (const moduleName of Object.keys(modules)) {
    if (modules[moduleName].actions && modules[moduleName].actions.init) {
        store.dispatch(`${moduleName}/init`)
    }
}

export default store
