import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL || 'https://connect.nicapi.eu/api/v2/'
const TIMEOUT = Number(process.env.VUE_APP_TIMEOUT)

const lumaservAPI = axios.create({
    baseURL: API_URL,
    headers: { 'Content-Type': 'application/json' },
    timeout: TIMEOUT,
    responseType: 'json',
    responseEncoding: 'utf8',
    showMessages: true
})

export default lumaservAPI
