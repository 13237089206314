import Vue from 'vue'
import Router from 'vue-router'
import { Project } from '@/core/services/store/models/project'
import { CloudServer } from '@/core/services/store/models/cloud_server'
import { IpAddress } from '@/core/services/store/models/ip_address'
import { FirewallRuleSet } from '@/core/services/store/models/firewall_rule_set'
import { FirewallAddressSet } from '@/core/services/store/models/firewall_address_set'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: () => import('@/view/layout/Layout'),
            children: [
                {
                    path: '/',
                    name: 'overview',
                    component: () => import('@/view/pages/Dashboard.vue')
                },
                {
                    path: '/',
                    component: () => import('@/view/layout/CustomerDashboardLayout.vue'),
                    children: [
                        {
                            path: '/customers/:customer_id/dashboard',
                            name: 'customer-dashboard',
                            component: () => import('@/view/pages/customers/CustomerDashboard')
                        },
                        {
                            path: '/projects/:uuid',
                            name: 'projects-single',
                            component: () => import('@/view/pages/projects/ProjectSingle'),
                            meta: {
                                entity: Project
                            }
                        },
                        {
                            path: '/servers/:id',
                            name: 'servers-single',
                            component: () => import('@/view/pages/cloudserver/CloudServerSingle'),
                            meta: {
                                entity: CloudServer
                            }
                        },
                        {
                            path: '/networks/:id',
                            name: 'networks-single',
                            component: () => import('@/view/pages/networks/NetworkSingle'),
                            meta: {
                                entity: CloudServer
                            }
                        },
                        {
                            path: '/addresses/:address',
                            name: 'addresses-single',
                            component: () => import('@/view/pages/addresses/AddressSingle'),
                            meta: {
                                entity: IpAddress
                            }
                        },
                        {
                            path: '/firewall/rule_sets/:id',
                            name: 'firewall-rule-sets-single',
                            component: () => import('@/view/pages/firewall_rule_sets/FirewallRuleSetSingle'),
                            meta: {
                                entity: FirewallRuleSet
                            }
                        },
                        {
                            path: '/firewall/address_sets/:id',
                            name: 'firewall-address-sets-single',
                            component: () => import('@/view/pages/firewall_address_sets/FirewallAddressSetSingle'),
                            meta: {
                                entity: FirewallAddressSet
                            }
                        }
                    ],
                    meta: {
                        navbarProjectManagement: true
                    }
                }
            ]
        },
        {
            path: '/',
            component: () => import('@/view/pages/auth/Auth'),
            children: [
                {
                    name: 'login',
                    path: '/login',
                    component: () => import('@/view/pages/auth/Login'),
                    meta: { guard: false }
                },
                {
                    name: 'register',
                    path: '/register',
                    component: () => import('@/view/pages/auth/Register'),
                    meta: { guard: false }
                }
            ]
        },
        {
            path: '*',
            redirect: '/404'
        },
        {
            // the 404 route, when none of the above matches
            path: '/404',
            name: '404',
            component: () => import('@/view/pages/NotFound'),
            meta: { guard: false }
        }
    ]
})

router.beforeEach((to, from, next) => {
    const guard = !to.meta.hasOwnProperty('guard') || to.meta.guard
    if (guard && (!localStorage.getItem('bearer_token') || localStorage.getItem('bearer_token').length === 0)) {
        next('/login')
        return
    }
    next()
})

export default router
