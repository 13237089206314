const BEARER_TOKEN = 'bearer_token'

export const getToken = () => {
    return window.localStorage.getItem(BEARER_TOKEN)
}

export const saveToken = token => {
    window.localStorage.setItem(BEARER_TOKEN, token)
}

export const destroyToken = () => {
    window.localStorage.removeItem(BEARER_TOKEN)
}

export default { getToken, saveToken, destroyToken }
