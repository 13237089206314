import { Model } from '@vuex-orm/core'
import api from '@/core/services/openapi.service'
import { Pricing } from '@/core/services/store/models/pricing'

export class Customer extends Model {
    static entity = 'customers'

    static primaryKey = 'id'

    static fields () {
        return {
            id: this.number(0),
            company_name: this.string(''),
            first_name: this.string(''),
            last_name: this.string(''),
            email: this.string(''),
            email_accounting: this.string(''),
            email_tech: this.string(''),
            email_newsletter: this.string(''),
            email_contact: this.string(''),
            email_abuse: this.string(''),
            street: this.string(''),
            house_number: this.string(''),
            zip_code: this.string(''),
            city: this.string(''),
            country_id: this.number(0),
            vat_id: this.string(''),
            amount: this.number(0),
            credit_limit: this.number(0),
            next_billing_cycle: this.string(''),
            created_at: this.string(''),
            updated_at: this.string(''),

            pricings: this.hasMany(Pricing, 'customer_id')
        }
    }

    static async fetchAll () {
        const response = await api.getCustomers()

        await this.insert({
            data: response.data
        })
    }
}
