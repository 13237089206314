import BearerService from '@/core/services/bearer.service'
import router from '@/router'

import { Project } from '@/core/services/store/models/project'
import { Network } from '@/core/services/store/models/network'
import { IpAddress } from '@/core/services/store/models/ip_address'
import { FirewallAddressSet } from '@/core/services/store/models/firewall_address_set'
import { FirewallRuleSet } from '@/core/services/store/models/firewall_rule_set'
import { Customer } from '@/core/services/store/models/customer'
import api from '@/core/services/openapi.service'

export const state = {
    errors: null,
    user: null,
    isAuthenticated: !!BearerService.getToken()
}

export const getters = {
    currentUser (state) {
        return state.user
    },
    isAuthenticated (state) {
        return state.isAuthenticated
    }
}

export const actions = {
    login ({ commit, dispatch, state }, credentials) {
        commit('PURGE_AUTH')
        return new Promise((resolve, reject) => {
            api.login(credentials)
                .then(response => {
                    commit('SET_AUTH', response.data)
                    resolve(response.data)
                })
                .catch(response => {
                    if (response.messages) {
                        commit(
                            'SET_ERROR',
                            response.messages.errors.map(error => error.message)
                        )
                    } else {
                        commit(
                            'SET_ERROR',
                            ['Der Login war nicht erfolgreich.']
                        )
                    }
                    reject(response)
                })
        })
    },
    logout ({ commit }) {
        api.logout()
            .then(() => {
                commit('PURGE_AUTH')
            })
    },
    register ({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            /*
             * TODO: Registration is not fully implemented
             * api.register(credentials)
             * .then(response => {
             * commit('SET_AUTH', response.data.data)
             * resolve(response.data.data)
             * })
             * .catch(({ response }) => {
             * commit('SET_ERROR', response.data.errors)
             * reject(response)
             * })
             */
        })
    },
    verify ({ commit }) {
        if (BearerService.getToken()) {
            api.getSelf()
                .then(response => {
                    commit('SET_AUTH', response.data)
                })
                .catch(() => {
                    commit('SET_ERROR', [])
                })
        } else {
            commit('PURGE_AUTH')
        }
    },
    updateUser ({ commit }, payload) {
        const { email, username, password, image, bio } = payload
        const user = { email, username, bio, image }

        if (password) {
            user.password = password
        }

        return api.updateUser(username, user).then(response => {
            commit('SET_AUTH', response.data)
            return response
        })
    }
}

export const mutations = {
    SET_ERROR (state, error) {
        state.errors = error
        state.isAuthenticated = false

        console.log(error)

        router.push({ name: 'login' })
    },

    SET_AUTH (state, data) {
        state.isAuthenticated = true
        state.user = data
        state.errors = {}
        if (data.token) {
            BearerService.saveToken(data.token)
        }
        Project.fetchAll()
        Network.fetchAll()
        FirewallAddressSet.fetchAll()
        FirewallRuleSet.fetchAll()
        Customer.fetchAll()
    },

    PURGE_AUTH (state) {
        BearerService.destroyToken()
        state.isAuthenticated = false
        state.user = {}
        state.errors = {}

        Project.deleteAll()
        Network.deleteAll()
        IpAddress.deleteAll()
        FirewallAddressSet.deleteAll()
        FirewallRuleSet.deleteAll()
        Customer.deleteAll()

        localStorage.removeItem('impersonateUser')
    }
}
