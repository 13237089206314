import { Model } from '@vuex-orm/core'
import api from '@/core/services/openapi.service'
import { Customer } from '@/core/services/store/models/customer'

export class Pricing extends Model {
    static entity = 'pricings'

    static primaryKey = 'id'

    static fields() {
        return {
            id: this.number(0),
            customer_id: this.number(0),

            customer: this.belongsTo(Customer, 'customer_id')
        }
    }

    static async fetchForCustomer(customer) {
        const response = await api.getCloudServerPricing({
            customer_id: customer.id
        })

        await this.insertOrUpdate(response)
    }
}
