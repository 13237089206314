import Vue from 'vue'
import store from '@/core/services/store'
import router from './router'
import App from './App.vue'
import MockService from '@/core/mock/mock.service'
import VueWait from 'vue-wait'
import VueNotification from 'vue-notification'
import VueSweetalert2 from 'vue-sweetalert2'

// Global 3rd party plugins
import 'popper.js'
import 'tooltip.js'
import PerfectScrollbar from 'perfect-scrollbar'

// Vue 3rd party plugins
import i18n from '@/core/plugins/vue-i18n'
import '@/core/plugins/portal-vue'
import '@/core/plugins/bootstrap-vue'
import '@/core/plugins/inline-svg'
import '@/core/plugins/apexcharts'
import fetchAll from './core/mixins/fetchAll'
import apiMixin from '@/core/mixins/apiMixin'
window.PerfectScrollbar = PerfectScrollbar

Vue.config.productionTip = false

// Remove this to disable mock API
MockService.init()

// Register dependencies
Vue.use(VueWait)
Vue.use(VueNotification)

// Register mixins
Vue.mixin(fetchAll)
Vue.mixin(apiMixin)

const options = {
    confirmButtonColor: '#ac0006',
    cancelButtonText: 'Abbrechen'
}
Vue.use(VueSweetalert2, options)

router.beforeEach((to, from, next) => {
    // Ensure we checked auth before each page load.
    Promise.all([store.dispatch('auth/verify')]).then(next)

    // reset config to initial state
    store.dispatch('config/resetLayout')

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0)
    }, 100)
})

export default new Vue({
    router,
    store,
    i18n,
    wait: new VueWait({ useVuex: false }),
    render: h => h(App)
}).$mount('#app')
