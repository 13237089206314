import axios from './axios'
import qs from 'qs'
import Vue from 'vue'
import BearerService from '@/core/services/bearer.service'
import store from '@/core/services/store'

const messages = {
    errors: [],
    warnings: [],
    success: []
}

axios.interceptors.request.use(config => {
    config.paramsSerializer = params => {
        return qs.stringify(params)
    }

    config.params = {
        config: {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        ...config.params
    }
    config.headers = {
        ...config.headers,
        'X-Impersonate': localStorage.getItem('impersonateUser') || '',
        'Authorization': 'Bearer ' + BearerService.getToken()
    }

    return config
}, err => {
    return Promise.reject(err)
})

axios.interceptors.response.use(response => {
    notifyMessages(response.data.messages)

    return response
}, function (error) {
    if (error.response.status === 401) {
        store.commit('auth/PURGE_AUTH')
    } else {
        notifyMessages(error.response.data.messages)
    }
    return Promise.reject(error)
})

const notifyMessages = items => {
    messages['errors'] = items.errors.map(item => item.message)
    messages['warnings'] = items.warnings.map(item => item.message)
    messages['success'] = items.success.map(item => item.message)

    notify()
}

const notify = () => {
    showMessages(messages['errors'], 'error')
    showMessages(messages['warnings'], 'warnings')
    showMessages(messages['success'], 'success')

    messages['errors'].shift()
    messages['warnings'].shift()
    messages['success'].shift()
}

const showMessages = (messages, type) => {
    const message = messages.join('<br><br>')
    if (!message) { return }

    Vue.notify({
        text: message,
        type: type
    })
}

export default axios
