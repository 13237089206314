// Germany
export const locale = {
    TRANSLATOR: {
        SELECT: 'Wähle deine Sprache'
    },
    MENU: {
        NEW: 'Neu',
        ACTIONS: 'Aktionen',
        CREATE_POST: 'Erstellen Sie einen neuen Beitrag',
        PAGES: 'Pages',
        FEATURES: 'Eigenschaften',
        APPS: 'Apps',
        DASHBOARD: 'Instrumententafel'
    },
    SIDEBAR: {
        DASHBOARD: 'Dashboard',
        COST_MANAGEMENT: 'Kosten-Management',
        IP_ADDRESSES: 'Ip-Adressen',
        IMPERSONATION: 'Identität'
    },
    SLOGAN: {
        HEADER: 'Willkommen im Cloud Panel!',
        DESC: 'Steuern und skalieren Sie Ihre Infrastruktur mit dem neuen Cloud Panel.'
    },
    AUTH: {
        GENERAL: {
            OR: 'Oder',
            SUBMIT_BUTTON: 'einreichen',
            NO_ACCOUNT: 'Hast du kein Konto?',
            SIGNUP_BUTTON: 'Anmelden',
            FORGOT_BUTTON: 'Passwort vergessen',
            BACK_BUTTON: 'Zurück',
            PRIVACY: 'Privatsphäre',
            LEGAL: 'Legal',
            CONTACT: 'Kontakt',
            IMPRINT: 'Impressum'
        },
        LOGIN: {
            TITLE: 'Anmeldung',
            DESC: 'Bitte geben Sie Ihren Benutzernamen und Ihr Passwort ein.',
            BUTTON: 'Anmelden'
        },
        FORGOT: {
            TITLE: 'Forgot Password?',
            DESC: 'Enter your email to reset your password',
            SUCCESS: 'Your account has been successfully reset.'
        },
        REGISTER: {
            TITLE: 'Registrieren',
            DESC: 'Geben Sie Ihre Details an.',
            SUCCESS: 'Your account has been successfuly registered.'
        },
        INPUT: {
            EMAIL: 'Email',
            FULLNAME: 'Fullname',
            PASSWORD: 'Passwort',
            CONFIRM_PASSWORD: 'Confirm Password',
            USERNAME: 'Nutzername'
        },
        VALIDATION: {
            INVALID: '{{name}} is not valid',
            REQUIRED: '{name} muss angegeben werden.',
            MIN_LENGTH: '{{name}} minimum length is {{min}}',
            AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
            NOT_FOUND: 'The requested {{name}} is not found',
            INVALID_LOGIN: 'The login detail is incorrect',
            REQUIRED_FIELD: 'Required field',
            MIN_LENGTH_FIELD: 'Minimum field length:',
            MAX_LENGTH_FIELD: 'Maximum field length:',
            INVALID_FIELD: 'Field is not valid'
        }
    },
    ECOMMERCE: {
        COMMON: {
            SELECTED_RECORDS_COUNT: 'Selected records count: ',
            ALL: 'All',
            SUSPENDED: 'Suspended',
            ACTIVE: 'Active',
            FILTER: 'Filter',
            BY_STATUS: 'by Status',
            BY_TYPE: 'by Type',
            BUSINESS: 'Business',
            INDIVIDUAL: 'Individual',
            SEARCH: 'Search',
            IN_ALL_FIELDS: 'in all fields',
            UPDATE: 'Aktualisieren'
        },
        ECOMMERCE: 'eCommerce',
        CUSTOMERS: {
            CUSTOMERS: 'Customers',
            CUSTOMERS_LIST: 'Customers list',
            NEW_CUSTOMER: 'New Customer',
            DELETE_CUSTOMER_SIMPLE: {
                TITLE: 'Customer Delete',
                DESCRIPTION: 'Are you sure to permanently delete this customer?',
                WAIT_DESCRIPTION: 'Customer is deleting...',
                MESSAGE: 'Customer has been deleted'
            },
            DELETE_CUSTOMER_MULTY: {
                TITLE: 'Customers Delete',
                DESCRIPTION: 'Are you sure to permanently delete selected customers?',
                WAIT_DESCRIPTION: 'Customers are deleting...',
                MESSAGE: 'Selected customers have been deleted'
            },
            UPDATE_STATUS: {
                TITLE: 'Status has been updated for selected customers',
                MESSAGE: 'Selected customers status have successfully been updated'
            },
            EDIT: {
                UPDATE_MESSAGE: 'Customer has been updated',
                ADD_MESSAGE: 'Customer has been created'
            }
        }
    },
    GENERAL: {
        NOW: 'Jetzt',
        SECOND: '{count} Sekunde | {count} Sekunden',
        MINUTE: '{count} Minute | {count} Minuten',
        HOUR: '{count} Stunde | {count} Stunden',
        DAY: '{count} Tag | {count} Tage'
    },
    DATACENTER: {
        COST_MANAGEMENT: {
            START: 'Start',
            END: 'Ende',
            INTERVAL: 'Intervall',
            CHOOSE_USER: 'Benutzer auswählen',
            DAILY_COSTS: 'Kosten pro Tag',
            CUMULATIVE_COSTS_SINCE_MONTH: 'Kumulierte Kosten seit Monatsanfang'
        },
        VSERVERS: {
            TITLE: 'Cloud Server',
            CREATE: {
                BUTTON: 'Erstellen',
                CONFIRM_BUTTON: 'Server erstellen',
                TITLE: 'Cloud Server erstellen'
            },
            SELECT_MULTIPLE: {
                BUTTON: 'Mehrere Server auswählen'
            },
            PER_DAY: 'Pro Tag',
            PER_MONTH: 'Pro Monat',
            SELECT_ALL: 'Alle auswählen',
            DESELECT_ALL: 'Auswahl aufheben',
            CONFIGURATION: {
                TITLE: 'Cloud Server verwalten',
                COMPUTE_POWER: 'Rechenleistung konfigurieren',
                CORES: 'Kern | Kerne',
                MEMORY: 'RAM',
                DISKS: {
                    DESC: 'Festplatten konfigurieren ({n}/{max})',
                    ONE: 'Festplatte {n}'
                },
                INTERFACES: {
                    DESC: 'Netzwerke konfigurieren ({n}/{max})',
                    PRIVATE_NETWORK: 'Privates Netz',
                    CHOOSE_NETWORK: 'Netzwerk auswählen',
                    IP_ADDRESS: 'IP-Adresse'
                }
            },
            DETAILS: {
                FIREWALL: {
                    TITLE: 'Firewall',
                    CREATE: 'Erstellen',
                    CONFIRM_CREATE: 'Regel erstellen',
                    DELETE: 'Löschen',
                    EDIT: 'Bearbeiten',
                    CONFIRM_EDIT: 'Regel bearbeiten',
                    COLUMNS: {
                        TITLE: 'Titel',
                        ORDER: 'Priorität',
                        DIRECTION: 'Richtung',
                        ACTION: 'Aktion',
                        INTERFACE: 'Interface',
                        SOURCE_ADDRESS: 'Quelladresse',
                        SOURCE_ADDRESS_SET: 'Quelladressset',
                        SOURCE_PORT: 'Quellport',
                        DESTINATION_ADDRESS: 'Zieladresse',
                        DESTINATION_ADDRESS_SET: 'Zieladresset',
                        DESTINATION_PORT: 'Zielport',
                        PROTOCOL: 'Protokoll',
                        ACTIONS: ''
                    },
                    SOURCE_ADDRESS: {
                        SOURCE_ADDRESS: 'Quelladresse',
                        SOURCE_ADDRESS_SET: 'Quelladressset'
                    },
                    DESTINATION_ADDRESS: {
                        DESTINATION_ADDRESS: 'Zieladresse',
                        DESTINATION_ADDRESS_SET: 'Zieladresset'
                    },
                    DIRECTIONS: {
                        IN: 'Eingehend',
                        OUT: 'Ausgehend'
                    },
                    ACTIONS: {
                        ACCEPT: 'Akzeptieren',
                        DROP: 'Verwerfen',
                        REJECT: 'Ablehnen'
                    },
                    RULE: 'Regel',
                    RULESET: 'Ruleset',
                    CONFIRM_RULESET: 'Ruleset hinzufügen',
                    CONFIRM_RULESET_EDIT: 'Ruleset ändern',
                    ADDRESS_SET: 'Adressset'
                },
                TASKS: {
                    COLUMNS: {
                        ACTION: 'Aktion',
                        INTERVAL: 'Intervall',
                        NEXT_EXECUTION: 'Nächste Ausführung',
                        LAST_EXECUTION: 'Letzte Ausführung',
                        TYPE: 'Typ',
                        CREATED_AT: 'Erstellt',
                        ACTIONS: 'Aktionen'
                    },
                    ACTIONS: {
                        START: 'Starten',
                        STOP: 'Stoppen',
                        SHUTDOWN: 'Herunterfahren',
                        RESTART: 'Neustarten',
                        BACKUP: 'Backup erstellen'
                    },
                    INTERVALS: {
                        HOUR: 'Stündlich',
                        DAY: 'Täglich',
                        WEEK: 'Wöchentlich',
                        MONTH: 'Monatlich',
                        YEAR: 'Jährlich'
                    }
                }
            },
            MASS_ACTIONS: {
                start: {
                    TITLE: 'Die folgenden Server starten?',
                    DESCR: '{servers}',
                    BUTTON: 'Ja, bitte starten',
                    OPEN_BUTTON: 'Starten'
                },
                shutdown: {
                    TITLE: 'Die folgenden Server herunterfahren (ACPI)?',
                    DESCR: '{servers}',
                    BUTTON: 'Ja, bitte herunterfahren',
                    OPEN_BUTTON: 'Herunterfahren'
                },
                stop: {
                    TITLE: 'Sollen die Server gestoppt werden (Power-Off)?',
                    DESCR: '{servers}',
                    BUTTON: 'Ja, bitte stoppen',
                    OPEN_BUTTON: 'Stoppen'
                }
            },
            MENU: {
                OVERVIEW: 'Übersicht',
                STATS: 'Statistiken',
                TASKS: 'Aufgaben',
                FIREWALL: 'Firewall'
            }
        },
        ADDRESSES: {
            CREATE: 'IP-Adressen erstellen',
            DELETE: 'Löschen',
            COLUMNS: {
                ADDRESS: 'Adresse',
                RDNS: 'Rdns',
                RESERVED: 'Reserved',
                PRICE: 'Preis',
                ACTIONS: 'Aktionen'
            }
        },
        PROJECTS: {
            ALL: 'Alle anzeigen',
            NOT_IN_PROJECT: 'Keine Projektzuordnung',
            PROJECT: 'Projekt: {value}',
            ADD: 'Projekt hinzufügen',
            CHANGE_PROJECT: 'Projekt ändern',
            DESELECT_PROJECT: 'Zuweisung aufheben'
        }
    }
}
