import { Model } from '@vuex-orm/core'
import api from '@/core/services/openapi.service'

export class CloudServerStatus extends Model {
    static entity = 'cloud_server_status';

    static primaryKey = 'id'

    static fields () {
        return {
            id: this.number(0),
            status: this.string('')
        }
    }

    isOnline() {
        return this.status === 'running'
    }

    static async fetchAll () {
        const response = await api.getCloudServerMassStatus()

        await CloudServerStatus.insertOrUpdate({
            data: Object.keys(response.data).map(k => response.data[k]).map(e => ({ ...e, id: e.vmid }))
        })
        return CloudServerStatus.all()
    }
}
