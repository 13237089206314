export const state = {
    breadcrumbs: []
}

export const getters = {
    /**
     * Get list of breadcrumbs for current page
     * @param state
     * @returns {*}
     */
    breadcrumbs (state) {
        return state.breadcrumbs
    },

    /**
     * Get the page title
     * @param state
     * @returns {*}
     */
    pageTitle (state) {
        const last = state.breadcrumbs[state.breadcrumbs.length - 1]
        if (last && last.title) {
            return last.title
        }
    }
}

export const actions = {
    /**
     * Set the breadcrumbs list
     * @param state
     * @param payload
     */
    set (state, payload) {
        state.commit('SET_BREADCRUMB', payload)
    },

    /**
     * Add breadcrumb
     * @param state
     * @param payload
     */
    add (state, payload) {
        if (typeof payload === 'object') {
            payload.forEach(item => state.commit('APPEND_BREADCRUMB', item))
        } else {
            state.commit('APPEND_BREADCRUMB', payload)
        }
    }
}

export const mutations = {
    APPEND_BREADCRUMB (state, payload) {
        state.breadcrumbs = [...state.breadcrumbs, payload]
    },

    SET_BREADCRUMB (state, payload) {
        state.breadcrumbs = payload
    }
}
